import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { SeoMetadataService } from '@shared/services/seo-metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'astrea-advance-searcher',
  templateUrl: './advance-searcher.component.html',
  styleUrls: ['./advance-searcher.component.scss']
})
export class AdvanceSearcherComponent implements OnInit {

  public years: any[] = [];
  public currentYear = new Date().getFullYear();
  public fromYear = 1950; // Minimun year on the BBDD with valid books for Web

  public formSearch = this.formBuilder.group({
    q: ['', Validators.required],
    from: [this.fromYear.toString()],
    to: [this.currentYear.toString()],
    priceFrom: [null],
    priceTo: [null],
    isbn: [''],
    operator: ['or'],
  });

  public authors!: any[];

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private seo: SeoMetadataService,
    private _t: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.setMetadata();
    this.buildYears();
    if (this.data && this.data.q) {
      this.formSearch.patchValue({ q: this.data.q });
    }
  }

  setMetadata(): void {
    this.seo.setMetadata({
      title: `Buscador | Editorial Astrea`,
      description: `Buscador avanzado de obras de la Editorial Astrea. Buscá entre miles de obras y autores.`
    });
  }

  buildYears(): void {
    for (let i = this.fromYear; i <= this.currentYear; i++) {
      this.years.push({ value: i, text: i });
    }
  }

  execSearcher(): void {
    const from = parseInt(this.formSearch.get('from')!.value);
    const to = parseInt(this.formSearch.get('to')!.value);
    if (from >= to) {
      this.formSearch.patchValue({ from: this.fromYear.toString(), to: this.currentYear.toString() });
      this.dispatchDateWarning();
    }
    this.router.navigate(['/searcher'], {
      queryParams: {
        q: this.formSearch.get('q')?.value,
        from: this.formSearch.get('from')?.value,
        to: this.formSearch.get('to')?.value,
        priceFrom: this.formSearch.get('priceFrom')?.value,
        priceTo: this.formSearch.get('priceTo')?.value,
        isbn: this.formSearch.get('isbn')?.value,
        operator: this.formSearch.get('operator')?.value,
        page: 1,
      },
      replaceUrl: true,
    });
  }

  dispatchDateWarning(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.reset_years_alert'),
      alertType: 'warning'
    }));
  }

}
