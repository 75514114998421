import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QuicklinkModule } from 'ngx-quicklink';

const ANGULAR_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  QuicklinkModule,
];
const ANGULAR_MODULE_EXPORT = [
  TranslateModule
];


/* Custom Modules */
import { MaterialModule } from '@material/material.module';

const CUSTOM_MODULES = [
  MaterialModule,
];


/* Components */
import { ProfileInvoicingComponent } from './components/@profile/profile-invoicing/profile-invoicing.component';
import { ProfilePersonalComponent } from './components/@profile/profile-personal/profile-personal.component';
import { ProfileResidenceComponent } from './components/@profile/profile-residence/profile-residence.component';

import { CashSuccessMpComponent } from './components/@purchases/cash-success/cash-success.component';
import { MPSuccessComponent } from './components/@purchases/mp-success/mp-success.component';
import { PPSuccessComponent } from './components/@purchases/pp-success/pp-success.component';

import { AlertNotificationComponent } from './components/alert-notification/alert-notification.component';
import { BookSimpleComponent } from './components/book-simple/book-simple.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuLeftnavComponent } from './components/menu-leftnav/menu-leftnav.component';
import { MenuNavbarComponent } from './components/menu-navbar/menu-navbar.component';
import { AdvanceSearcherComponent } from './components/menu-navbar/advance-searcher/advance-searcher.component';
import { MobileRedirectAuthorComponent } from './components/mobile-redirect-author/mobile-redirect-book.component';
import { MobileRedirectBookComponent } from './components/mobile-redirect-book/mobile-redirect-book.component';
import { PaginatorComponent } from './components/paginator/paginator.component';

const COMPONENTS = [
  ProfileInvoicingComponent,
  ProfilePersonalComponent,
  ProfileResidenceComponent,

  CashSuccessMpComponent,
  MPSuccessComponent,
  PPSuccessComponent,

  AlertNotificationComponent,
  BookSimpleComponent,
  FaqComponent,
  FooterComponent,
  LoadingComponent,
  MenuLeftnavComponent,
  MenuNavbarComponent,
  AdvanceSearcherComponent,
  MobileRedirectAuthorComponent,
  MobileRedirectBookComponent,
  PaginatorComponent,
];


/* Pipes */
import { AstreaCurrencyPipe } from '@shared/pipes/astrea-currency.pipe';

const CUSTOM_PIPES = [
  AstreaCurrencyPipe
]

@NgModule({
  declarations: [
    COMPONENTS,
    CUSTOM_PIPES,
  ],
  imports: [
    ANGULAR_MODULES,
    CUSTOM_MODULES,
  ],
  exports: [
    COMPONENTS,
    ANGULAR_MODULE_EXPORT,
    CUSTOM_PIPES,
  ],
  providers: []
})
export class SharedModule { }
