<header class="astrea-menu-navbar">

    <div class="container astrea-toolbar d-flex j-c-betweena-i-center">

        <div class="d-flex j-c-start a-i-center w-25">
            <a class="astrea-logo" routerLink="/" title="Ir a la home">
                <img class="img-responsive" src="/assets/images/astrea-brand/logo_web_white.png" alt="{{ 'logo' | translate }}">
            </a>
        </div>

        <div class="d-flex j-c-end j-c-sm-between a-i-center flex-wrap w-100">

            <form [formGroup]="formSearch" class="astrea-search hide-mobile d-flex j-c-between a-i-center">
                <input type="text" name="q" formControlName="q" placeholder="{{ 'search.placeholder' | translate }}">
                <button (click)="handleSearcher()" (keydown.enter)="handleSearcher()" class="btn-form d-flex j-c-center a-i-center">
                    <mat-icon>search</mat-icon>
                </button>
                <button (click)="handleAdvanceSearcher()" class="advance-searcher-btn">
                    {{ 'navigation.search_adv' | translate }}
                </button>
            </form>

            <div class="astrea-user-options d-flex j-c-end a-i-center">
                <div class="astrea-header-select mr-2">
                    <select (ngModelChange)="handleLanguage()" name="language" [(ngModel)]="language">
                        <option value="es-ar">ARG</option>
                        <option value="en-us">ENG</option>
                    </select>
                </div>
                <div class="astrea-header-select mr-2">
                    <select (ngModelChange)="handleCurrency()" name="currency" [(ngModel)]="currency">
                        <option value="ARS">AR$</option>
                        <option value="USD">U$D</option>
                        <option value="EUR">€UR</option>
                    </select>
                </div>

                <!-- MENU LOGIN -->
                <div class="hide-mobile">
                    <div *ngIf="(isLoggedIn$ | async); else navBarLogout">
                        <button class="astrea-header-link mr-2" [matMenuTriggerFor]="profile" title="{{ 'navigation.menu' | translate }}" attr.aria-label="{{ 'navigation.menu' | translate }}">
                            <div class="d-flex j-c-between a-i-center">
                                {{ userName ? userName : ('navigation.menu' | translate) }}
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </div>
                        </button>
                        <mat-menu #profile="matMenu">
                            <div class="astrea-menu-dropdown">
                                <a routerLink="/profile/personal" title="{{ 'navigation.profile' | translate }}" tabindex="0" mat-menu-item>
                                    <mat-icon attr.aria-label="{{ 'navigation.profile' | translate }}">account_circle</mat-icon>
                                    {{ 'navigation.profile' | translate }}
                                </a>
                                <a routerLink="/history/purchase" title="{{ 'navigation.history' | translate }}" mat-menu-item>
                                    <mat-icon attr.aria-label="{{ 'navigation.history' | translate }}">history</mat-icon>
                                    {{ 'navigation.history' | translate }}
                                </a>
                                <a routerLink="/wishlist" title="{{ 'navigation.wishlist' | translate }}" mat-menu-item>
                                    <mat-icon attr.aria-label="{{ 'navigation.wishlist' | translate }}">stars</mat-icon>
                                    {{ 'navigation.wishlist' | translate }}
                                </a>
                                <a routerLink="/contact" title="{{ 'navigation.contact' | translate }}" mat-menu-item>
                                    <mat-icon attr.aria-label="{{ 'navigation.contact' | translate }}">feedback</mat-icon>
                                    {{ 'navigation.contact' | translate }}
                                </a>
                                <a (click)="logout()" (keydown.enter)="logout()" class="btn-logout" title="{{ 'navigation.logout' | translate }}" mat-menu-item>
                                    {{ 'navigation.logout' | translate }}
                                </a>
                            </div>
                        </mat-menu>
                    </div>
                </div>

                <!-- MENU LOGOUT -->
                <ng-template #navBarLogout>
                    <div class="d-flex j-c-center a-i-center">
                        <a class="astrea-header-link mr-2" [routerLink]="['/register']" [queryParams]="{ redirect: redirect }" title="{{ 'navigation.register' | translate }}">
                            {{ 'navigation.register' | translate }}
                        </a>
                        <a class="astrea-header-link ml-0 mr-2" [routerLink]="['/login']" [queryParams]="{ redirect: redirect }" title="{{ 'navigation.login' | translate }}">
                            {{ 'navigation.login' | translate }}
                        </a>
                    </div>
                </ng-template>

            </div>

            <div class="d-flex j-c-start a-i-center w-75 hide-mobile">
                <div class="mt-3">

                    <!-- MENU PRINCIPAL -->
                    <button class="mx-1" [matMenuTriggerFor]="tienda" title="{{ 'navigation.store' | translate }}" attr.aria-label="{{ 'navigation.store' | translate }}">
                        <div class="d-flex j-c-between a-i-center">
                            {{ 'navigation.store' | translate }}
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </button>
                    <mat-menu #tienda="matMenu">
                        <div class="astrea-menu-dropdown">
                            <a routerLink="/store" title="{{ 'navigation.catalog' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.catalog' | translate }}</span>
                            </a>
                            <a routerLink="/authors" title="{{ 'navigation.authors' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.authors' | translate }}</span>
                            </a>
                            <a routerLink="/collections" title="{{ 'navigation.collections' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.collections' | translate }}</span>
                            </a>
                            <a routerLink="/materias" title="{{ 'navigation.matter' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.matter' | translate }}</span>
                            </a>
                            <a routerLink="/astreavirtual" title="{{ 'navigation.book_digital' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.book_digital' | translate }}</span>
                            </a>
                        </div>
                    </mat-menu>

                    <button class="mx-1" [matMenuTriggerFor]="la_editorial" title="{{ 'navigation.editorial.editorial' | translate }}" attr.aria-label="{{ 'navigation.editorial.editorial' | translate }}">
                        <div class="d-flex j-c-between a-i-center">
                            {{ 'navigation.editorial.editorial' | translate }}
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </button>
                    <mat-menu #la_editorial="matMenu">
                        <div class="astrea-menu-dropdown">
                            <a routerLink="/about/history" title="{{ 'navigation.editorial.history' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.history' | translate }}</span>
                            </a>
                            <a routerLink="/about/concept" title="{{ 'navigation.editorial.concept' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.concept' | translate }}</span>
                            </a>
                            <a routerLink="/doctrine" title="{{ 'navigation.doctrine' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.doctrine' | translate }}</span>
                            </a>
                            <a routerLink="/about/proceso-editorial" title="{{ 'navigation.editorial.process' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.process' | translate }}</span>
                            </a>
                            <a href="/assets/about/Pautas_Astrea_2017.pdf" target="_blank" title="{{ 'navigation.editorial.pautas' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.pautas' | translate }}</span>
                            </a>
                            <a href="/assets/about/Referato.pdf" target="_blank" title="{{ 'navigation.editorial.referato' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.referato' | translate }}</span>
                            </a>
                        </div>
                    </mat-menu>

                    <a routerLink="/recent" title="{{ 'navigation.recent' | translate }}" class="mx-1">
                        {{ 'navigation.recent' | translate }}
                    </a>
                    <a routerLink="/contact" title="{{ 'navigation.contact' | translate }}" class="mx-1">
                        {{ 'navigation.contact' | translate }}
                    </a>

                </div>
            </div>

            <div class="d-flex j-c-end a-i-center w-25 hide-mobile">
                <div class="d-flex j-c-center a-i-center mt-3">
                    <a routerLink="/cart" [matBadge]="cartStorageCount" class="mb-1" mat-icon-button matSuffix attr.aria-label="{{ 'navigation.go_cart' | translate }}" type="button" tabindex="-1" rel="nofollow">
                        <mat-icon>shopping_cart</mat-icon>
                    </a>
                    <a class="d-flex j-c-center a-i-center ml-3" href="https://astreavirtual.com/login" target="_blank" title="{{ 'navigation.go_av' | translate }}">
                        <img src="/assets/images/astrea-brand/av_isologo_white.svg" alt="Isologo Astrea Virtual">
                        {{ 'navigation.go_av' | translate }}
                    </a>
                </div>
            </div>

        </div>

        <div class="hide-desktop a-i-center" mat-icon-button>
            <div class="d-flex j-c-around a-i-end">
                <button class="searcher-mobile d-flex j-c-center a-i-center" (click)="handleAdvanceSearcher()">
                    <mat-icon>search</mat-icon>
                </button>
                <a routerLink="/cart" class="mr-1" [matBadge]="cartStorageCount" mat-icon-button matSuffix attr.aria-label="{{ 'navigation.go_cart' | translate }}" type="button" tabindex="-1" rel="nofollow">
                    <mat-icon>shopping_cart</mat-icon>
                </a>
                <button (click)="handleMenuClose()" class="d-flex j-c-center a-i-center mr-2 mb-1">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>

    </div>

</header>
