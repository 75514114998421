<mat-dialog-content class="astrea-adv-search">
    <div class="search-title mb-3">
        <h3>Búsqueda avanzada</h3>
    </div>
    <form [formGroup]="formSearch">

        <mat-form-field class="main-input" appearance="fill">
            <mat-label>{{ 'search.placeholder' | translate }}</mat-label>
            <input type="text" matInput formControlName="q">
            <mat-error class="t-right">{{ 'search.enter_search' | translate }}</mat-error>
        </mat-form-field>

        <mat-radio-group class="search-radio d-flex j-c-start a-i-center mb-2" attr.aria-label="{{ 'search.type.type' | translate }}" formControlName="operator">
            <mat-radio-button value="or">{{ 'search.type.or' | translate }}</mat-radio-button>
            <mat-radio-button value="and">{{ 'search.type.and' | translate }}</mat-radio-button>
        </mat-radio-group>

        <div class="grid-50-50">
            <div class="astrea-date-box p-2 mt-0 w-100" [matMenuTriggerFor]="dateDropdown">
                <small>{{ 'search.filter.year' | translate }}</small>
                <div class="from-to d-flex j-c-around a-i-center mt-1">
                    <small>{{ 'from' | translate }}: {{ formSearch.get('from')!.value }}</small>
                    <small>{{ 'to' | translate }}: {{ formSearch.get('to')!.value }}</small>
                </div>
            </div>
            <mat-menu #dateDropdown="matMenu">
                <div *ngIf="years.length > 0" class="astrea-date-dropdown" (click)="$event.stopPropagation();">
                    <p>{{ 'search.filter.year' | translate }}</p>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'from' | translate }}</mat-label>
                        <mat-select formControlName="from">
                            <mat-option *ngFor="let y of years" value="{{ y.value }}">{{ y.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'to' | translate }}</mat-label>
                        <mat-select formControlName="to">
                            <mat-option *ngFor="let y of years" value="{{ y.value }}">{{ y.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-menu>
            <mat-form-field appearance="fill">
                <mat-label>ISBN</mat-label>
                <input type="text" matInput formControlName="isbn">
            </mat-form-field>
        </div>

        <div class="grid-50-50">
            <mat-form-field appearance="fill">
                <mat-label>{{ 'search.filter.priceFrom' | translate }}</mat-label>
                <input type="number" matInput formControlName="priceFrom">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'search.filter.priceTo' | translate }}</mat-label>
                <input type="number" matInput formControlName="priceTo">
            </mat-form-field>
        </div>

        <div class="d-flex j-c-end">
            <button (click)="execSearcher()" class="btn btn-search w-50" mat-button>
                {{ 'search.search' | translate }}
            </button>
        </div>

    </form>
</mat-dialog-content>
